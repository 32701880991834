import React, {useEffect, useState} from 'react';
import {Box, Chip, CircularProgress, Grid, Link, Typography} from '@mui/material';
import {useMapContext} from "/@/context/mapContext";
import {useThemeContext} from "/@/context/themeContext";
import {useMapData} from "/@/context/useMapData";
import {PollutantType} from "/@/constants/chartContants";
import AirQualityChart from "/@/components/graphs/AirQualityChart";
import {t} from "i18next";
import {Trans} from "react-i18next";
import {styled} from "@mui/material/styles";

const StyledTypography = styled(Typography)(({theme}) => ({
    paddingTop: theme.spacing(0.5),
    textAlign: 'right',
    width: '100%',
    color: theme.palette.text.secondary,
}));
const AirQualityGraphs = () => {
    // 1. Move all hooks to the top
    const {isMobile} = useThemeContext();
    const {
        airQualityData,
        selectedMobilePollutant,
        setSelectedMobilePollutant
    } = useMapContext();

    const {getAverageReadingsByBorough, getSensorReadingsByDateRange, isLoadingSensorData} = useMapData();
    const [isLoadingChartData, setIsLoadingChartData] = useState<boolean>(true);

    // Effect to handle initial data load
    useEffect(() => {

        // IF THERE IS ALREADY DATA, SKIP
        if (airQualityData.pm25.length > 0 || airQualityData.pm10.length > 0 || airQualityData.no2.length > 0) {
            setIsLoadingChartData(false);
            return;
        }

        const fetchInitialData = async () => {
            await getAverageReadingsByBorough();
            setIsLoadingChartData(false);
        }
        fetchInitialData();
    }, [getAverageReadingsByBorough]);

    const renderDesktopGraphs = () => {
        return (
            <>
                <AirQualityChart pollutant="PM2.5" airQualityData={airQualityData} isMapPage={true}/>
                <AirQualityChart pollutant="PM10" airQualityData={airQualityData} isMapPage={true}/>
                <AirQualityChart pollutant="NO2" airQualityData={airQualityData} isMapPage={true}/>
            </>
        );
    };

    const renderMobileGraphs = () => {
        return (
            <>
                <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1}>
                    {(['PM2.5', 'PM10', 'NO2'] as PollutantType[]).map((pollutant) => (
                        <Chip
                            key={pollutant}
                            label={pollutant}
                            onClick={() => setSelectedMobilePollutant(pollutant)}
                            color={selectedMobilePollutant === pollutant ? 'success' : 'default'}
                        />
                    ))}
                </Box>
                <AirQualityChart
                    pollutant={selectedMobilePollutant}
                    airQualityData={airQualityData}
                    isMapPage={true}
                />
            </>
        );
    };

    // 4. Conditional rendering
    if (isLoadingSensorData || isLoadingChartData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Grid container>
            {isMobile ? renderMobileGraphs() : renderDesktopGraphs()}
            <StyledTypography variant="caption" color="primary">
                <Trans i18nKey={"source_declaration"}>
                    {t("source_declaration")}{" "}
                    <Link target="_blank" href="https://www2.purpleair.com/" sx={{color: 'blue'}}>
                        PurpleAir
                    </Link>
                    ,{" "}
                    <Link
                        target="_blank"
                        href="https://www.londonair.org.uk/LondonAir/Default.aspx"
                        sx={{color: 'blue'}}
                    >
                        London Air
                    </Link>
                    ,{" "}
                    <Link
                        target="_blank"
                        href="https://www.airqualityengland.co.uk/"
                        sx={{color: 'blue'}}
                    >
                        AirQualityEngland
                    </Link>{" "}
                    &{" "}
                    <Link target="_blank" href="https://www.breathelondon.org/" sx={{color: 'blue'}}>
                        Powered by Breathe London
                    </Link>
                </Trans>
            </StyledTypography>
        </Grid>
    );
};

export default AirQualityGraphs;