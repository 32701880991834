import {styled} from "@mui/material/styles";
import {Box, Grid} from "@mui/material";

export const PageGridWrapper = styled(Grid)(({theme}) => ({
    display: 'flex',
    maxHeight: '100vh',
    [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(100vh - 50px)',
    },
}));

export const MapArea = styled(Box)(({theme}) => ({
    flexGrow: 1,
    position: 'relative',
    padding: '15px',
    paddingLeft: '20px',
    marginBottom: undefined,
    marginTop: undefined,
    [theme.breakpoints.down('md')]: {
        padding: '0',
        marginBottom: '80px',
    },
}));

export const LeftSide = styled(Grid)(({theme}) => ({
    height: '100vh',
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 50px)',
    },
}));

export const SidebarGridWrapper = styled(Grid)(({theme}) => ({
    height: '95vh',
    overflowY: 'auto',
    background: 'linear-gradient(to bottom, #f0f7fa, 50%, #e6f3f7 100%)',
    borderRadius: '5px 5px 5px 5px',
    position: 'relative',
    bottom: 0,
    zIndex: 2,
    padding: 10,
    display: 'none',
    [theme.breakpoints.up('md')]: { // This shows the sidebar for non-mobile devices
        display: 'block',
    },
}));

export const PullUpTab = styled('div')(({theme}) => ({
    position: 'fixed',
    bottom: '90px',
    left: 0,
    right: 0,
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid',
    borderColor: 'grey.300',
    backgroundColor: '#cee7ef',
    zIndex: 100,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: { // Hide pull-up tab on non-mobile devices
        display: 'none',
    },
}));

export const MapTourGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    position: 'absolute',
    right: '39.33%',
    top: '3%',
    zIndex: 9999,
    [theme.breakpoints.down('md')]: { // Hide pull-up tab on non-mobile devices
        right: 10,
        top: 20
    },
}))